@page {
  margin: 0;
}

@media print {
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
   
  }
}



html, body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* Invoice */
.invoice {
  margin:0;
  font-family: Arial, sans-serif;
  border:none
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 80px;  /* Adjust width as necessary */
  height: auto;
}

.order-number {
  font-size: 24px;
  font-weight: bold;
}

.vendor {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.items {
  margin-bottom: 30px;
  display: block; 
}

.order-items{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
}

.item-name {
  width:40%;
}



.price,
.quantity,.item-total
 {
  text-align: center;
  width: 20%;
}

.header{
  font-weight: bold!important;
  color:#000;
  text-align: left!important;

}

.total {
  text-align: right;
  font-weight: bold;
  font-size: 20px;
  margin-top: 40px;
 
}


.invoice {
  width: 100%; /* Subtract padding */
  padding: 20px;
}
.invoice-wrapper{
  margin:0;
  border:none
}

@media only screen and (max-width: 1100px){

  .invoice{
    margin:0px;
    font-size: 10px;
  }

  .item-name {
    width:35%;
  }
  

  
  .price,
  .quantity,.item-total
   {
    text-align: center;
    width: 15%;
  }


  .total {
    font-size: 15px;
    
   
  }
  
}